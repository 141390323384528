import { Box } from '@material-ui/core';
import { UniButton, UniTypography } from 'components';
import React from 'react';

type StateCallback = (value: string[]) => string[];

interface Props {
    breadcrumbs: string[];
    setBreadcrumbs: (value: string[] | StateCallback) => void;
    search: string;
}

const SearchPageBreadcrumbs = ({
    breadcrumbs,
    search,
    setBreadcrumbs,
}: Props) => {
    return (
        <Box mt={-2} mb={2}>
            {search && (
                <UniTypography
                    color="typographyGray100"
                    variant="body1"
                    weight="medium"
                >
                    Search result for: {search}
                </UniTypography>
            )}

            {breadcrumbs.length > 0 && (
                <Box mb={3} display="flex" alignItems="center">
                    <UniButton
                        variant="text"
                        color="secondary"
                        onClick={() => {
                            setBreadcrumbs([]);
                        }}
                    >
                        Home
                    </UniButton>
                    <UniTypography
                        color="typographyGray100"
                        variant="footnote"
                        weight="medium"
                    >
                        /
                    </UniTypography>
                    {breadcrumbs.map((breadcrumb, index) => (
                        <React.Fragment key={index}>
                            <UniButton
                                variant="text"
                                color="secondary"
                                disabled={index === breadcrumbs.length - 1}
                                onClick={() => {
                                    setBreadcrumbs((prev) =>
                                        prev.filter(
                                            (_, inIdx) => inIdx <= index
                                        )
                                    );
                                }}
                            >
                                {breadcrumb}
                            </UniButton>
                            {index < breadcrumbs.length - 1 && (
                                <UniTypography
                                    color="typographyGray100"
                                    variant="footnote"
                                    weight="medium"
                                >
                                    /
                                </UniTypography>
                            )}
                        </React.Fragment>
                    ))}
                </Box>
            )}
        </Box>
    );
};

export default SearchPageBreadcrumbs;
