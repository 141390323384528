import { Button, ButtonProps } from '@material-ui/core';
import { motion } from 'framer-motion';
import React, { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { Colors, Shape } from '../../../theme';
import { Size } from './UniButton';
// Will get error if we try to pass a boolean to a extended Material component
type ZeroOrOne = 0 | 1;
type StyledButtonProps = {
    existsChild: boolean;
    start: ZeroOrOne;
    end: ZeroOrOne;
    size: Size;
    loading: ZeroOrOne;
    disablePadding?: boolean;
    children?: React.ReactNode;
    borderRadius?: [number, number, number, number];
};

// const getPosition = (start: ZeroOrOne, end: ZeroOrOne, loading: ZeroOrOne) => {
//   if (loading) return 'align-items: center';
//   if (!!start) return 'justify-content: start';
//   if (!!end) return 'justify-content: space-between';
// };

export const getSize = (size: Size) => {
    if (size === 'large') return 2;
    if (size === 'medium') return 1.5;
    if (size === 'small') return 1;
    return 1.5;
};

export const getBackgroundColorFilter = (color: any, variant: any) => {
    if (color === 'primary' && variant === 'main')
        return 'rgba(255, 255, 255, 0.3)';
    if (color === 'primary' || color === 'primaryFade')
        return Colors.Primary._10;
    return Colors.Gray2._100;
};

export const StyledButton = styled(
    ({
        existsChild,
        start,
        end,
        size,
        loading,
        disablePadding,
        children,
        borderRadius,
        ...rest
    }: StyledButtonProps & Omit<ButtonProps, keyof StyledButtonProps>) => (
        <Button {...rest}> {children} </Button>
    )
)`
    position: relative;
    min-width: 0;
    border-radius: ${({ theme }) => theme.spacing(1)}px;
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
    font-size: ${({ theme }) => theme.typography.body1.fontSize};
    border-radius: ${({ theme, borderRadius }) =>
        borderRadius
            ? theme.spacing(...borderRadius)
            : `${theme.spacing(1)}px`};
    display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
    justify-content: center;
    padding-left: ${({ theme, size }) =>
        size === 'small' ? theme.spacing(2) : theme.spacing(3)}px;
    padding-right: ${({ theme, size }) =>
        size === 'small' ? theme.spacing(2) : theme.spacing(3)}px;
    gap: 12px;
    height: ${({ size }) =>
        size === 'large' ? '56px' : size === 'medium' ? '48px' : '40px'};
    ${({ size, theme, existsChild, start }) =>
        !existsChild &&
        !!start &&
        `padding: 0px ${theme.spacing(getSize(size))}px`}
    ${({ disablePadding }) => disablePadding && 'padding:0; height:auto;'}
`;

type ContentProps = {
    loading: boolean;
};
export const Content = styled(
    ({
        loading,
        ...rest
    }: ContentProps &
        Omit<HTMLAttributes<HTMLDivElement>, keyof ContentProps>) => (
        <div {...rest} />
    )
)<{ size: Size; end: ZeroOrOne; start: ZeroOrOne }>`
    margin: 0
        ${({ theme, size }) =>
            size === 'small' ? theme.spacing(1) : theme.spacing(2)}px;
    opacity: ${({ loading }) => (loading ? 0 : 1)};
    ${({ theme, end }) => end === 0 && `margin-right: ${theme.spacing(0)}px`};
    ${({ theme, start }) =>
        start === 0 && `margin-left: ${theme.spacing(0)}px`};
`;

export const StyledCounter = styled.div<{
    size: Size;
    color: any;
    variant: any;
}>`
    display: flex;
    height: ${({ theme, size }) =>
        size === 'large'
            ? theme.spacing(3.375)
            : size === 'medium'
            ? theme.spacing(3)
            : theme.spacing(2.625)}px;
    width: ${({ theme }) => theme.spacing(2.5)}px;
    margin-left: ${({ theme }) => theme.spacing(2)}px;
    justify-content: center;
    background-color: ${({ color, variant }) =>
        getBackgroundColorFilter(color, variant)};
    align-items: center;
    border-radius: ${({ theme }) => theme.spacing(0.5)}px;
`;

export const Loader = styled.div`
    display: grid;
    place-content: center;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
`;

export const StyledToolTip = styled(motion.div)<{ backgroundColor?: string }>`
    position: absolute;
    left: 0;
    top: -50px;

    background-color: ${({ backgroundColor }) =>
        backgroundColor ? backgroundColor : Colors.Typography.Gray_30};
    padding: ${({ theme }) => theme.spacing(1, 2)};
    border-radius: ${Shape.BorderRadius}px;
    white-space: nowrap;

    z-index: 999;

    &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        left: 10px;
        bottom: -13px;

        border: 7px solid transparent;
        border-top: 7px solid ${Colors.Typography.Gray_30};
    }
`;
