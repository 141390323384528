import { Box } from '@material-ui/core';
import { ReactComponent as CareIcon } from 'assets/svg/Icons/Heart.svg';
import { UniIconWrapper, UniTypography } from 'components';
import React from 'react';
import {
    StyledContentWrapper,
    StyledUnderline,
    StyledUniTypography,
} from './AboutUs.style';

interface Props {}

const AboutUsContent = (props: Props) => {
    return (
        <>
            <StyledContentWrapper>
                <Box>
                    {/* <StyledLogo stretched /> */}
                    <StyledUniTypography
                        color="secondary100"
                        variant="xl"
                        weight="bold"
                    >
                        Why
                        <br />
                        Us
                    </StyledUniTypography>
                    <StyledUnderline />
                </Box>
                <Box>
                    <UniIconWrapper
                        size="large"
                        color="secondary100"
                        withBackground
                    >
                        <CareIcon />
                    </UniIconWrapper>
                    <Box mt={1} />
                    <UniTypography variant="header2" weight="bold">
                        We care
                    </UniTypography>
                    <UniTypography variant="body2" weight="regular">
                        Our students and partners are the only reason why we
                        exist and their success is what gives our job purpose.
                    </UniTypography>
                </Box>
                <Box>
                    <UniIconWrapper
                        size="large"
                        color="primary100"
                        withBackground
                    >
                        <CareIcon />
                    </UniIconWrapper>
                    <Box mt={1} />
                    <UniTypography variant="header2" weight="bold">
                        We are honest
                    </UniTypography>
                    <UniTypography variant="body2" weight="regular">
                        We are sincere in intentions and methods, both inside
                        and outside organisation. We are genuine and
                        responsible, assuming and telling the truth in any
                        circumstance.
                    </UniTypography>
                </Box>
                <Box>
                    <UniIconWrapper
                        size="large"
                        color="primary100"
                        withBackground
                    >
                        <CareIcon />
                    </UniIconWrapper>
                    <Box mt={1} />
                    <UniTypography variant="header2" weight="bold">
                        We help
                    </UniTypography>
                    <UniTypography variant="body2" weight="regular">
                        We are building an ecosystem that helps students reach
                        the right university and vice-versa.
                    </UniTypography>
                </Box>
                <Box>
                    <UniIconWrapper
                        size="large"
                        color="otherGreen100"
                        withBackground
                    >
                        <CareIcon />
                    </UniIconWrapper>
                    <Box mt={1} />
                    <UniTypography variant="header2" weight="bold">
                        We dream big
                    </UniTypography>
                    <UniTypography variant="body2" weight="regular">
                        In a business with tempting short term results, we
                        deliberately choose not to undersell our vision for
                        immediate gains.
                    </UniTypography>
                </Box>
                <Box>
                    <UniIconWrapper
                        size="large"
                        color="facebook100"
                        withBackground
                    >
                        <CareIcon />
                    </UniIconWrapper>
                    <Box mt={1} />
                    <UniTypography variant="header2" weight="bold">
                        We are BEST
                    </UniTypography>
                    <UniTypography variant="body2" weight="regular">
                        Each day we become Better, Efficient, Stronger and
                        Tenacious.
                    </UniTypography>
                </Box>
            </StyledContentWrapper>
        </>
    );
};

export default AboutUsContent;
