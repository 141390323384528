import React from 'react';
import {
    LogoStyle,
    StyledDarkIcon,
    StyledLightIcon,
    StyledLogoIcon,
} from './uniLogo.style';

interface UniLogoProps {
    light?: boolean;
    stretched?: boolean;
    text?: boolean;
    size?: 'S' | 'M' | 'L';
    onClick?: Function;
}

const UniLogo: React.FC<UniLogoProps> = ({
    light = false,
    stretched = false,
    text = false,
    size = 'M',
    onClick,
    ...props
}) => {
    return (
        <>
            <LogoStyle onClick={() => onClick && onClick()} {...props}>
                {!text && <StyledLogoIcon size={size} stretched={stretched} />}
                {!stretched && (
                    <>
                        {light ? (
                            <StyledLightIcon size={size} />
                        ) : (
                            !stretched && <StyledDarkIcon size={size} />
                        )}
                    </>
                )}
            </LogoStyle>
        </>
    );
};

export default UniLogo;
