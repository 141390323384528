import { Box } from '@material-ui/core';
import { countries } from 'common/countries';
import {
    UniAvatar,
    UniButton,
    UniChip,
    UniHeader,
    UniLogo,
    UniTypography,
} from 'components';
import { useBreakpoints } from 'hooks';
import queryString from 'query-string';
import React, { useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { ActionMeta, OnChangeValue, StylesConfig } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import useAuth from 'store/auth';
import usePixel from 'store/pixel';
import { Colors } from 'theme';
import { OptionType } from 'types';
import countryIso from '../../../common/countryIso.json';
import { StyledCountriesWrapper } from './SearchPage.style';

interface Props {
    setDrawerOpen: (value: boolean) => void;
}

const SearchPageHeader = ({ setDrawerOpen }: Props) => {
    const { code } = useAuth();
    const [searchTries, setSearchTries] = useState(5);
    const canTry = !!searchTries || !!code;
    const { track } = usePixel();
    const countryFilters = countryIso.filter((item) =>
        countries.includes(item.alpha2)
    );
    const { isMobile } = useBreakpoints();
    const history = useHistory();
    const { search } = useLocation();
    const queries = queryString.parse(search) as {
        query: string;
        country: string[];
    };
    const [selectedCountries, setSelectedCountries] = useState<string[]>(
        queries?.country ? [queries?.country].flat(1) : []
    );
    const [query, setQuery] = useState(queries.query || '');

    const handleSearch = () => {
        let countryQuery = '';
        let searchQuery = '';
        if (selectedCountries.length) {
            countryQuery = selectedCountries
                .map((item) => `country=${item}`)
                .join('&');
        }
        searchQuery = `query=${query}`;

        let URLParams = '';
        if (countryQuery && searchQuery)
            URLParams = `${searchQuery}&${countryQuery}`;
        else URLParams = `${searchQuery}${countryQuery}`;

        track('search_event', {
            query,
            selectedCountries,
        });

        setSearchTries((prev) => prev - 1);
        history.push({ pathname: '', search: `?${URLParams}` });
    };

    useMemo(
        () => (query || selectedCountries.length) && handleSearch(),
        [selectedCountries]
    );

    const handleChange = (
        newValue: OnChangeValue<OptionType, true>,
        actionMeta: ActionMeta<OptionType>
    ) => setQuery(newValue.map(({ value }) => value).join(','));

    const customStyles: StylesConfig<OptionType> = {
        container: (provided, state) => ({
            ...provided,
            flex: 1,
            borderColor: state.isFocused
                ? Colors.Primary._100
                : Colors.Border.Gray_70,
        }),
        option: (provided, state) => ({
            ...provided,
            borderBottom: '1px dotted pink',
            color: Colors.Typography.Gray_100,
            padding: '8px 16px',
            cursor: 'pointer',
            backgroundColor: state.isSelected
                ? Colors.Primary._10
                : 'transparent',
            ':hover': {
                backgroundColor: Colors.Primary._10,
            },
        }),
        control: (provided, state) => ({
            ...provided,
            height: isMobile ? 40 : 48,
            borderRadius: 8,
            borderColor: Colors.Border.Gray_70,
            boxShadow: 'none',
            ':hover': {
                borderColor: Colors.Border.Gray_70,
            },
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';

            return { ...provided, opacity, transition };
        },
        placeholder: (provided) => ({
            ...provided,
            textAlign: 'left',
            fontWeight: 400,
        }),
        input: (provided) => ({ ...provided, textAlign: 'left' }),
        menuList: (provided) => ({ ...provided, textAlign: 'left' }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: Colors.Primary._10,
            color: Colors.Primary._100,
            padding: '4px 8px',
            borderRadius: '24px',
        }),
        valueContainer: (provided) => ({
            ...provided,
            height: isMobile ? 40 : 48,
            overflow: 'auto',
        }),
    };

    return (
        <UniHeader>
            <Box
                mt={2}
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
            >
                <Box
                    style={{ cursor: 'pointer' }}
                    onClick={() => window.open('https://uniapply.org')}
                >
                    <UniLogo text size={isMobile ? 'S' : 'L'} />
                </Box>
                <Box display="flex" alignItems="center">
                    <UniButton
                        variant="text"
                        color="secondary"
                        size={isMobile ? 'small' : 'medium'}
                        onClick={() => history.push('/about')}
                    >
                        {!isMobile ? 'About Us' : 'About'}
                    </UniButton>
                    {!!code ? (
                        <>
                            <UniTypography
                                variant="subheader"
                                weight="medium"
                                color="typographyGray70"
                            >
                                {`STUDENT`}
                            </UniTypography>
                            <Box mr={1} />
                            <UniAvatar
                                size="S"
                                color="secondary100"
                                label={`STUDENT`}
                            />
                        </>
                    ) : (
                        <UniButton
                            variant="secondary"
                            size={isMobile ? 'small' : 'medium'}
                            onClick={() => setDrawerOpen(true)}
                        >
                            {!isMobile ? ' Sign up for more' : 'Sign up'}
                        </UniButton>
                    )}
                </Box>
            </Box>
            <Box py={3} textAlign="center">
                <UniTypography
                    color="secondary100"
                    variant="header2"
                    weight="bold"
                >
                    {!isMobile
                        ? 'Search anything and find your dream programme'
                        : 'Find your dream programme'}
                </UniTypography>
                <Box display="flex" alignItems="center" justifyContent="center">
                    <UniTypography color="secondary100" variant="header3">
                        {!isMobile
                            ? 'Powered by Artificial Intelligence'
                            : 'Powered by A.I.'}
                    </UniTypography>
                </Box>

                <Box
                    mt={2}
                    mb={1}
                    width="100%"
                    display="flex"
                    alignItems="flex-start"
                >
                    <CreatableSelect
                        isMulti
                        styles={customStyles}
                        onChange={handleChange}
                        placeholder={
                            !isMobile
                                ? 'Search anything to find your desired programme...'
                                : 'Search...'
                        }
                        value={
                            !!query
                                ? query.split(',').map((item) => ({
                                      value: item,
                                      label: item,
                                  }))
                                : []
                        }
                        options={[
                            { value: 'Social Media', label: 'Social Media' },
                            { value: 'Marketing', label: 'Marketing' },
                            {
                                value: 'I like computers',
                                label: 'I like computers',
                            },
                        ]}
                    />
                    {/* 
                    <UniTextField
                        fullWidth
                        disabled={!canTry}
                        value={query}
                        placeholder='Try "Facebook, Google or Social Media"'
                        onChange={(e) => setQuery(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.keyCode === 13) {
                                handleSearch();
                            }
                        }}
                    /> 
                    */}
                    <Box ml={2}>
                        <UniButton
                            disabled={!canTry}
                            fullWidth={false}
                            size={isMobile ? 'small' : 'medium'}
                            onClick={() => {
                                handleSearch();
                            }}
                        >
                            Search
                        </UniButton>
                    </Box>
                </Box>

                {!code && (
                    <Box ml={1} mb={2} width="100%" textAlign="left">
                        <UniTypography
                            color={
                                canTry ? 'typographyGray100' : 'stateAlert100'
                            }
                            variant="footnote"
                            weight="medium"
                        >
                            {canTry
                                ? `You have ${searchTries} more ${
                                      searchTries > 1 ? 'tries' : 'try'
                                  }.`
                                : 'Your trial has ended. You need to sign up before you continue.'}
                        </UniTypography>
                    </Box>
                )}

                <StyledCountriesWrapper>
                    {countryFilters.map((item, index) => (
                        <Box
                            style={{ cursor: canTry ? 'pointer' : 'unset' }}
                            mr={1}
                            onClick={() =>
                                canTry &&
                                setSelectedCountries((prev) =>
                                    prev.includes(item.alpha2)
                                        ? prev.filter(
                                              (prevItem) =>
                                                  prevItem !== item.alpha2
                                          )
                                        : [...prev, item.alpha2]
                                )
                            }
                        >
                            <UniChip
                                label={item.name}
                                key={index}
                                color={
                                    selectedCountries.includes(item.alpha2)
                                        ? 'green'
                                        : 'grey'
                                }
                            />
                        </Box>
                    ))}
                </StyledCountriesWrapper>
            </Box>
        </UniHeader>
    );
};

export default SearchPageHeader;
