import { Box } from '@material-ui/core';
import {
    UniButton,
    UniCard,
    UniChip,
    UniIconWrapper,
    UniTypography,
} from 'components';
import { UniMetricsBase } from 'components/_common/uniMetricsBase/UniMetricsBase';
import React from 'react';
import Flag from 'react-world-flags';
import usePixel from 'store/pixel';
import { ReactComponent as LocationIcon } from '../../../assets/svg/Icons/Location.svg';
import countriesIso from '../../../common/countryIso.json';
import { StyledUniWrapper } from './SearchPage.style';

interface Props {
    breadcrumbs: string[];
    programsMap: any[];
    setBreadcrumbs: (value: string[]) => void;
}

const SearchPageContent = ({
    breadcrumbs,
    programsMap,
    setBreadcrumbs,
}: Props) => {
    const { track } = usePixel();
    const programsLength = programsMap
        ?.find((item) => item.key === breadcrumbs[0])
        ?.countries.find((item: any) => item.name === breadcrumbs[1])
        ?.programs?.length;

    const Filler =
        programsLength && programsLength < 4
            ? [...new Array(4 - programsLength)].map((item) => <Box />)
            : [];

    return (
        <>
            {breadcrumbs.length === 0 && (
                <StyledUniWrapper>
                    {programsMap?.map((category: any, index: number) => (
                        <UniCard
                            size="S"
                            border
                            key={index}
                            hoverable
                            onClick={() => {
                                setBreadcrumbs([category.key]);
                                track('key_selected', { key: category.key });
                            }}
                        >
                            <Box
                                height="100%"
                                display="flex"
                                flexDirection="column"
                            >
                                <UniTypography
                                    variant="header3"
                                    color="secondary100"
                                    weight="medium"
                                >
                                    {category?.key}
                                </UniTypography>
                                <Box flex={1} />
                                <Box mt={2} mb={-2} display="flex">
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        flex={1}
                                    >
                                        {category.countries.map(
                                            (country: any) => (
                                                <>
                                                    <Flag
                                                        width="24"
                                                        code={country?.flag}
                                                    />
                                                    <Box ml={1} />
                                                </>
                                            )
                                        )}
                                    </Box>
                                    <UniTypography
                                        variant="footnote"
                                        color="typographyGray70"
                                        weight="regular"
                                    >
                                        {category?.count} results
                                    </UniTypography>
                                </Box>
                            </Box>
                        </UniCard>
                    ))}
                </StyledUniWrapper>
            )}

            {breadcrumbs.length === 1 && (
                <StyledUniWrapper>
                    {programsMap
                        .find((item) => item.key === breadcrumbs[0])
                        .countries.map((country: any, index: number) => (
                            <UniCard
                                size="S"
                                key={index}
                                border
                                hoverable
                                onClick={() => {
                                    setBreadcrumbs([
                                        breadcrumbs[0],
                                        country?.name,
                                    ]);
                                    track('country_selected', {
                                        key: country?.name,
                                    });
                                }}
                            >
                                <Box
                                    height="100%"
                                    display="flex"
                                    flexDirection="column"
                                >
                                    <UniTypography
                                        variant="header3"
                                        color="secondary100"
                                        weight="medium"
                                    >
                                        {country?.name}
                                    </UniTypography>
                                    <Box mt={2} mb={-2} display="flex">
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            flex={1}
                                        >
                                            <Flag
                                                width="24"
                                                code={country?.flag}
                                            />
                                        </Box>
                                        <Box flex={1} />
                                        <UniTypography
                                            variant="footnote"
                                            color="typographyGray70"
                                            weight="regular"
                                        >
                                            {country?.count} results
                                        </UniTypography>
                                    </Box>
                                </Box>
                            </UniCard>
                        ))}
                </StyledUniWrapper>
            )}

            {breadcrumbs.length === 2 && (
                <StyledUniWrapper>
                    {programsMap
                        .find((item) => item.key === breadcrumbs[0])
                        .countries.find(
                            (item: any) => item.name === breadcrumbs[1]
                        )
                        .programs.map((program: any, index: number) => (
                            <UniCard
                                size="S"
                                border
                                key={index}
                                onClick={() => {
                                    track('program_selected', {
                                        key: program?.name,
                                    });
                                }}
                            >
                                <Box
                                    height="100%"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="space-between"
                                >
                                    <Box>
                                        <UniTypography
                                            variant="header3"
                                            color="secondary100"
                                        >
                                            {program?.name}
                                        </UniTypography>
                                        <UniTypography
                                            variant="body2"
                                            color="secondary70"
                                        >
                                            {program?.universityName}
                                        </UniTypography>

                                        <Box mt={2} />
                                        <UniMetricsBase
                                            size="small"
                                            Icon={
                                                <UniIconWrapper>
                                                    <LocationIcon />
                                                </UniIconWrapper>
                                            }
                                            variant="iconed"
                                            label="Country"
                                            value={
                                                countriesIso.find(
                                                    (item) =>
                                                        item.alpha2 ===
                                                        program.address_country
                                                )?.name || 'Unspecified'
                                            }
                                        />
                                        <Box mt={1} />
                                        <UniMetricsBase
                                            size="small"
                                            Icon={
                                                <UniIconWrapper>
                                                    <LocationIcon />
                                                </UniIconWrapper>
                                            }
                                            variant="iconed"
                                            label="City"
                                            value={
                                                countriesIso.find(
                                                    (item) =>
                                                        item.alpha2 ===
                                                        program.address_city
                                                )?.name || 'Unspecified'
                                            }
                                        />
                                    </Box>

                                    <Box
                                        mt={2}
                                        mb={-2}
                                        width="100%"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <UniButton
                                            size="small"
                                            variant="secondary"
                                            onClick={() =>
                                                window.open(
                                                    `https://uniapply.org/programs/${program.id}/general`
                                                )
                                            }
                                        >
                                            Apply now
                                        </UniButton>
                                        <UniChip
                                            label={program.key}
                                            color="green"
                                        />
                                    </Box>
                                </Box>
                            </UniCard>
                        ))}
                    {Filler.map(() => (
                        <Box />
                    ))}
                </StyledUniWrapper>
            )}
        </>
    );
};

export default SearchPageContent;
