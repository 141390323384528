import { Box } from '@material-ui/core';
import { UniButton, UniOAuth, UniTextField, UniTypography } from 'components';
import UniModalDrawerHeader from 'components/_common/uniModalDrawer/UniModalDrawerHeader';
import React, { useState } from 'react';
import { BASE_URL } from '../../../envConfig.json';
import {
    StyledDrawerWrapper,
    StyledFixedDrawer,
    StyledSuccessIllustration,
    UniModalDrawerContent,
} from './SearchPage.style';

interface Props {
    canTry: boolean;
    drawerOpen: boolean;
    setDrawerOpen: (value: boolean) => void;
}

const SearchPageDrawer = ({ canTry, drawerOpen, setDrawerOpen }: Props) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');

    const handleLoginByEmail = async (email: string) => {
        const formData = new FormData();
        formData.append('email', email);
        setLoading(true);
        fetch(`${BASE_URL[process.env.NODE_ENV]}/`, {
            method: 'POST',
            mode: 'cors',
            body: formData,
        })
            .then((response) => {
                setSuccess(true);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                setErrorMessage('Please enter a valid email!');
            });
    };

    return (
        <StyledDrawerWrapper isOpen={!canTry || drawerOpen}>
            <StyledFixedDrawer
                side={'right'}
                isOpen={!canTry || drawerOpen}
                onClick={(e) => e.stopPropagation()}
            >
                <UniModalDrawerHeader
                    title={'Sign up'}
                    onClose={() => setDrawerOpen(false)}
                />
                <UniModalDrawerContent>
                    {success ? (
                        <>
                            <UniTypography
                                variant="body1"
                                weight="medium"
                                color="secondary100"
                            >
                                You have signed up succesfully!
                            </UniTypography>
                            <Box my={5}>
                                <StyledSuccessIllustration />
                            </Box>
                            <UniTypography
                                variant="body2"
                                weight="regular"
                                color="typographyBlack100"
                            >
                                Please verify your email so you can get full
                                access to our website.
                            </UniTypography>
                        </>
                    ) : (
                        <>
                            <UniTypography
                                variant="footnote"
                                weight="medium"
                                color="typographyGray70"
                            >
                                Signup with your details below to get the full
                                UniApply experience and search through as many
                                programmes as you want!
                            </UniTypography>
                            <Box mb={3} />
                            <UniTextField
                                fullWidth
                                label="Email"
                                helperText={errorMessage}
                                placeholder="Enter your email"
                                onChange={(e) => setEmail(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleLoginByEmail(email);
                                    }
                                }}
                            />
                            <UniButton
                                fullWidth
                                variant="secondary"
                                loading={loading}
                                onClick={() => {
                                    handleLoginByEmail(email);
                                }}
                            >
                                Sign up
                            </UniButton>
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                mt={3}
                            >
                                <UniTypography
                                    variant="subheader"
                                    weight="medium"
                                    color="typographyGray100"
                                >
                                    Or connect via
                                </UniTypography>
                                <Box mt={1} />
                                <UniOAuth google facebook login short />
                            </Box>
                        </>
                    )}
                </UniModalDrawerContent>
            </StyledFixedDrawer>
        </StyledDrawerWrapper>
    );
};

export default SearchPageDrawer;
