import React from 'react';
import { Content, StyledLayout } from './Layout.style';

const Layout: React.FC<any> = ({ children }) => {
    return (
        <StyledLayout>
            <Content
                id="uni-page-container"
                isLoggedIn={false}
                maxWidth={false}
            >
                {children}
            </Content>
        </StyledLayout>
    );
};

export default Layout;
