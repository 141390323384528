import React, { useEffect } from 'react';
import usePixel from 'store/pixel';

interface Props {}

const PixelProvider: React.FC<Props> = ({ children }) => {
    const { init } = usePixel();

    useEffect(() => init(), []);

    return <>{children}</>;
};

export default PixelProvider;
