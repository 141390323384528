import React from 'react';
import {
    Circle,
    Shadow,
    StyledLogoName,
    StyledWrapper,
} from './UniListLoader.style';

const UniListLoader = () => {
    return (
        <StyledWrapper>
            <Circle />
            <Circle />
            <Circle />
            <Shadow />
            <Shadow />
            <Shadow />
            <StyledLogoName />
        </StyledWrapper>
    );
};
export default UniListLoader;
