import styled from 'styled-components';
import { Colors, Shadows, theme } from 'theme';

export const StyledContactUsWrapper = styled.div`
    position: fixed;
    bottom: 15px;
    right: 15px;
    padding: ${theme.spacing(1, 3)};
    background-color: ${Colors.Secondary._100};
    box-shadow: ${Shadows.shadow3};
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
`;
