import { Grid } from '@material-ui/core';
import ContactUs from 'components/_common/contactUs/ContactUs';
import { useProgramsMap } from 'hooks';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useAuth from 'store/auth';
import { UniListLoader } from '../..';
import { BASE_URL } from '../../../envConfig.json';
import { EmptyState } from '../../_common/emptyState/EmptyState';
import UniAuthPageStyled from '../../_common/uniPage/UniPage.style';
import SearchPageBreadcrumbs from './SearchPageBreadcrumbs';
import SearchPageContent from './SearchPageContent';
import SearchPageDrawer from './SearchPageDrawer';
import SearchPageHeader from './SearchPageHeader';

interface Props {}

const SearchPage: React.FC<Props> = ({ children, ...rest }) => {
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const { search } = useLocation();
    const [categories, setCategories] = useState<any[]>([]);
    const { setCode } = useAuth();
    const [breadcrumbs, setBreadcrumbs] = useState<string[]>([]);
    const programsMap = useProgramsMap(categories);

    const getMyUser = () => {
        setBreadcrumbs([]);
        setLoading(true);

        fetch(`${BASE_URL[process.env.NODE_ENV]}/users/me`, {
            method: 'GET',
            mode: 'cors',
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(response);
                setLoading(false);
                if (response.detail.includes('Unauthorized')) {
                    setCode('');
                }
            })
            .catch((err) => {
                setLoading(false);
                // setIsLoggedIn(false);
            });
    };

    const handleSearch = () => {
        setBreadcrumbs([]);
        setLoading(true);

        fetch(`${BASE_URL[process.env.NODE_ENV]}/search${search}`, {
            method: 'GET',
            mode: 'cors',
        })
            .then((response) => response.json())
            .then((response) => {
                setLoading(false);
                setCategories(response);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    useMemo(() => search !== '' && handleSearch(), [search]);

    useEffect(() => {
        getMyUser();
    }, []);

    return (
        <>
            <SearchPageHeader setDrawerOpen={setDrawerOpen} />
            <UniAuthPageStyled>
                <Grid item xs={12}>
                    <SearchPageBreadcrumbs
                        breadcrumbs={breadcrumbs}
                        setBreadcrumbs={setBreadcrumbs}
                        search={''}
                    />
                    {categories.length === 0 && !loading && (
                        <EmptyState type="programs" />
                    )}

                    {loading && <UniListLoader />}

                    {!loading && (
                        <SearchPageContent
                            breadcrumbs={breadcrumbs}
                            programsMap={programsMap}
                            setBreadcrumbs={setBreadcrumbs}
                        />
                    )}
                </Grid>
            </UniAuthPageStyled>
            <SearchPageDrawer
                canTry={true}
                drawerOpen={drawerOpen}
                setDrawerOpen={setDrawerOpen}
            />

            <ContactUs />
        </>
    );
};

export default SearchPage;
