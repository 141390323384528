import { Box } from '@material-ui/core';
import {
    UniAvatar,
    UniButton,
    UniHeader,
    UniLogo,
    UniTypography,
} from 'components';
import { useBreakpoints } from 'hooks';
import React from 'react';

interface Props {
    canTry: boolean;
    setDrawerOpen: (value: boolean) => void;
    // searchTries: number;
    isLoggedIn: boolean;
}

const AboutUsHeader = ({ canTry, isLoggedIn, setDrawerOpen }: Props) => {
    const { isMobile } = useBreakpoints();

    return (
        <UniHeader>
            <Box
                mt={2}
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
            >
                <Box
                    style={{ cursor: 'pointer' }}
                    onClick={() => window.open('https://uniapply.org')}
                >
                    <UniLogo text size={isMobile ? 'S' : 'L'} />
                </Box>
                <Box display="flex" alignItems="center">
                    <UniButton
                        variant="text"
                        color="secondary"
                        size={isMobile ? 'small' : 'medium'}
                        onClick={() => setDrawerOpen(true)}
                    >
                        {!isMobile ? 'About Us' : 'About'}
                    </UniButton>
                    {isLoggedIn ? (
                        <>
                            <UniTypography
                                variant="subheader"
                                weight="medium"
                                color="typographyGray70"
                            >
                                {`STUDENT`}
                            </UniTypography>
                            <Box mr={1} />
                            <UniAvatar
                                size="S"
                                color="secondary100"
                                label={`STUDENT`}
                            />
                        </>
                    ) : (
                        <UniButton
                            variant="secondary"
                            size={isMobile ? 'small' : 'medium'}
                            onClick={() => setDrawerOpen(true)}
                        >
                            {!isMobile ? ' Sign up for more' : 'Sign up'}
                        </UniButton>
                    )}
                </Box>
            </Box>
            <Box py={3} textAlign="center">
                <UniTypography
                    color="secondary100"
                    variant="header1"
                    weight="bold"
                >
                    About Us
                </UniTypography>
                <Box
                    mx="auto"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    maxWidth="550px"
                >
                    <UniTypography color="secondary100" variant="body2">
                        We are the Youni tribe! We’re a group of like minded
                        people bound by the same values, and driven by a unique
                        vision.
                    </UniTypography>
                </Box>
            </Box>
        </UniHeader>
    );
};

export default AboutUsHeader;
