import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useAuth from 'store/auth';
import { BASE_URL } from '../../../envConfig.json';

interface Props {}

const VerifyCode = (props: Props) => {
    const { search } = useLocation();
    const history = useHistory();
    const { setCode } = useAuth();
    const { code } = queryString.parse(search) as { code: string };

    const sendCode = async () => {
        await fetch(
            `${BASE_URL[process.env.NODE_ENV]}/verify-code?code=${code}`,
            {
                method: 'GET',
                mode: 'cors',
            }
        );
    };

    useEffect(() => {
        if (code) {
            code !== 'SOCIAL' && sendCode();
            setCode(code);
            history.push('/');
        }
    }, [code]);

    return <></>;
};

export default VerifyCode;
