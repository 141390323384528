import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../../assets/svg/Icons/Close.svg';
import { ComponentColors } from '../../../theme';

export const StyledTagItem = styled.div<{ color: 'grey' | 'green' }>`
    display: flex;
    height: ${({ theme }) => theme.spacing(4.25)}px;
    width: fit-content;
    gap: ${({ theme }) => theme.spacing(1)};
    align-items: center;
    justify-content: space-between;
    padding-left: ${({ theme }) => theme.spacing(2)}px;
    padding-right: ${({ theme }) => theme.spacing(2)}px;
    background-color: ${({ color }) =>
        color === 'grey'
            ? ComponentColors['typographyGray10']
            : ComponentColors['secondary10']};
    color: ${({ color }) =>
        color === 'grey'
            ? ComponentColors['typographyGray70']
            : ComponentColors['secondary70']};
    border-radius: 24px;
`;

export const DisciplineItem = styled.div`
    display: flex;
    height: ${({ theme }) => theme.spacing(5.625)}px;
    width: fit-content;
    align-items: center;
    justify-content: space-between;
    padding: ${({ theme }) => theme.spacing(1.5, 2)};
    border-radius: 24px;
    border: 1px solid #848383;
`;

export const StyledCloseIcon = styled(CloseIcon)`
    padding: 10px;
    margin-right: ${({ theme }) => theme.spacing(-2)}px;
    border-radius: 50%;
    cursor: pointer;
    width: 100%;
    height: 100%;

    &:hover {
        background-color: ${ComponentColors['otherHover05']};
    }

    path {
        fill: ${({ color }) =>
            color === 'grey'
                ? ComponentColors['typographyGray70']
                : ComponentColors['secondary100']};
    }
`;
