import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import { ComponentColors, TComponentColors } from '../../../theme';
import { Transform, Weight } from './types';

interface Props {
    weight: Weight;
    unicolor: TComponentColors;
    transform: Transform;
}

const getWeight = (weight: Weight) => {
    if (weight === 'regular') return 400;
    if (weight === 'medium') return 500;
    if (weight === 'semi-bold') return 600;
    if (weight === 'bold') return 700;
    return 500;
};

export const StyledTypography = styled(Typography)<Props>`
    font-weight: ${({ weight }) => getWeight(weight)};
    color: ${({ unicolor }) => ComponentColors[unicolor]};
    text-transform: ${({ transform }) => transform};
    text-overflow: ellipsis;
    white-space: pre-line;
`;
