import { Overrides } from '@material-ui/core/styles/overrides';
import { Colors, Shadows, Typography } from './uiConfig';

// Project overrides
export default {
    MuiBadge: {
        root: {
            verticalAlign: 'auto',
        },
    },
    // Button
    MuiButton: {
        root: {
            textTransform: 'capitalize',
        },
    },
    MuiDrawer: {
        root: {
            color: Colors.Typography.Gray_70,
        },
        paperAnchorDockedLeft: {
            color: Colors.Typography.Gray_70,
            'border-right': 'none',
        },
    },
    MuiListItem: {
        root: {
            'background-color': 'white !important',
        },
    },

    MuiList: {
        padding: {
            paddingTop: '0px',
        },
    },
    // Tooltip
    MuiTooltip: {
        tooltip: {
            backgroundColor: Colors.Typography.Black_100,
            color: Colors.White._100,
            borderRadius: '4px',
            fontWeight: Typography.Weight.Regular,
            fontSize: Typography.subheader,
        },
        arrow: {
            color: Colors.Typography.Gray_30,
        },
    },
    // Dialog
    MuiDialog: {
        paper: {
            border: `1px solid ${Colors.Border.Gray_100}`,
            boxShadow: Shadows.shadow1,
        },
        paperWidthLg: {
            maxWidth: '1200px',
        },
    },

    MuiAutocomplete: {
        input: {
            paddingLeft: '10px !important',
            maxWidth: '100%',
        },
        clearIndicator: {
            color: Colors.Typography.Gray_70,
        },
    },

    // Input / Form
    MuiInputBase: {
        root: {
            height: '56px',
            background: Colors.White._100,
        },
    },

    MuiInputAdornment: {
        positionStart: {
            marginRight: '12px',
        },
        positionEnd: {
            marginLeft: '12px',
        },
    },
    MuiOutlinedInput: {
        root: {
            borderRadius: '8px',
            height: '56px',
            padding: 0,
            '&$disabled': {
                borderColor: Colors.Typography.Gray_50,
            },
            '&$focused': {
                borderColor: Colors.Secondary._100 + '!important',
            },
        },
        notchedOutline: {
            border: 'none',
        },
        input: {
            padding: '16px 24px',
            '&:not($disabled)': {
                color: Colors.Typography.Gray_100,
            },
            fontWeight: 400,
        },
        multiline: {
            padding: '16px 16px 16px 24px',
        },
        adornedStart: {
            paddingLeft: '20px',
        },
    },

    MuiFormControl: {
        textarea: {
            padding: '16px 24px',
            '&:not($disabled)': {
                color: Colors.Typography.Gray_100,
            },
            fontWeight: 400,
        },
    },

    MuiRadio: {
        root: {
            borderColor: 'red',
        },
    },
    MuiFormHelperText: {
        contained: {
            display: 'absolute',
            marginLeft: 0,
            fontSize: Typography.subheader,
        },
    },
    // Tab - Tabs
    MuiTab: {
        root: {
            fontSize: Typography.body2,
            textTransform: 'capitalize',
            '@media (min-width: 600px)': {
                minWidth: '72px',
            },
        },
        textColorPrimary: {
            color: Colors.Typography.Black_100,
        },
    },

    MuiTabs: {
        scroller: {
            overflow: 'auto !important',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
        },
    },

    // Menu
    MuiMenu: {
        paper: {
            borderRadius: '8px',
            border: `1px solid ${Colors.Border.Gray_100}`,
            boxShadow: Shadows.shadow1,
        },
    },
    MuiMenuItem: {
        root: {
            color: Colors.Typography.Gray_100,
            fontSize: Typography.body2,
            lineHeight: '24px',
            fontWeight: 400,
            paddingTop: '12px',
            paddingBottom: '12px',
            paddingLeft: '24px',
            paddingRight: '24px',
        },
    },

    MuiDivider: {
        root: {
            backgroundColor: Colors.Border.Gray_100,
        },
    },

    //Typography
    MuiTypography: {
        h1: {
            fontSize: Typography.xl,
            lineHeight: '48px',
        },
        h2: {
            fontSize: Typography.header1,
            lineHeight: '42px',
        },
        h3: {
            fontSize: Typography.header2,
            lineHeight: '36px',
        },
        h4: {
            fontSize: Typography.header3,
            lineHeight: '31.5px',
        },

        body1: {
            fontSize: Typography.body1,
            lineHeight: '27px',
        },
        body2: {
            fontSize: Typography.body2,
            lineHeight: '24px',
        },
        subtitle1: {
            fontSize: Typography.subheader,
            lineHeight: '21px',
        },
        caption: {
            fontSize: Typography.footnote,
            lineHeight: '18px',
        },
        overline: {
            fontSize: Typography.small,
            lineHeight: '15px',
        },
    },
} as Overrides;
