import styled from 'styled-components';
import { ReactComponent as LogoIconSvg } from '../../../assets/svg/LogoIcon.svg';
import { ReactComponent as LogoNameDarkSvg } from '../../../assets/svg/LogoNameDark.svg';
import { ReactComponent as LogoNameLightSvg } from '../../../assets/svg/LogoNameLight.svg';

export const LogoStyle = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledLogoIcon = styled(LogoIconSvg)<{
    size: any;
    stretched: boolean;
}>`
    margin-right: ${({ theme, stretched }) =>
        !stretched ? theme.spacing(1) : '0'}px;
    width: ${({ theme, size }) =>
        size === 'M' ? theme.spacing(4) : theme.spacing(5)}px;
    height: ${({ theme, size }) =>
        size === 'M' ? theme.spacing(4) : theme.spacing(5)}px;
`;

export const StyledDarkIcon = styled(LogoNameDarkSvg)<{ size: any }>`
    height: ${({ theme, size }) =>
        size === 'M' ? theme.spacing(4) : theme.spacing(5)}px;
    width: ${({ size }) => (size === 'L' ? '129px' : '80px')};
`;
export const StyledLightIcon = styled(LogoNameLightSvg)<{ size: any }>`
    height: ${({ theme, size }) =>
        size === 'M' ? theme.spacing(4) : theme.spacing(5)}px;
    width: ${({ size }) => (size === 'L' ? '129px' : '80px')};
`;

export const StyledTitleWrapper = styled.div<{ isMobile: boolean }>`
    display: flex;
    gap: 16px;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: ${({ theme }) => theme.spacing(10, 0, 7, 0)};
    ${({ theme }) => theme.breakpoints.down('sm')} {
        margin: ${({ theme }) => theme.spacing(5, 0, 3)};
    }
`;
