import { Colors } from './uiConfig';

const ComponentColors = {
    primary100: Colors.Primary._100,
    primary70: Colors.Primary._70,
    primary50: Colors.Primary._50,
    primary10: Colors.Primary._10,
    primary5: Colors.Primary._5,
    secondary100: Colors.Secondary._100,
    secondary70: Colors.Secondary._70,
    secondary50: Colors.Secondary._50,
    secondary10: Colors.Secondary._10,
    secondary5: Colors.Secondary._5,
    white100: Colors.White._100,
    white30: Colors.White._30,
    white10: Colors.White._10,
    typographyBlack100: Colors.Typography.Black_100,
    typographyGray100: Colors.Typography.Gray_100,
    typographyGray70: Colors.Typography.Gray_70,
    typographyGray50: Colors.Typography.Gray_50,
    typographyGray30: Colors.Typography.Gray_30,
    typographyGray10: Colors.Typography.Gray_10,
    borderGray100: Colors.Border.Gray_100,
    borderGray70: Colors.Border.Gray_70,
    gray1_100: Colors.Gray1._100,
    gray1_70: Colors.Gray1._70,
    gray2_100: Colors.Gray2._100,
    gray2_80: Colors.Gray2._80,
    gray2_40: Colors.Gray2._40,
    stateAlert100: Colors.State.Alert_100,
    otherPurple100: Colors.Other.Purple_100,
    otherYellow100: Colors.Other.Yellow_100,
    otherGreen100: Colors.Other.Green_100,
    otherHover05: Colors.Other.Hover_05,
    google100: Colors.Other.Google_100,
    facebook100: Colors.Other.Facebook_100,
};

export default ComponentColors;
