import { Box } from '@material-ui/core';
import { useBreakpoints } from 'hooks';
import React from 'react';
import { UniTypography } from '../..';
import { ReactComponent as EmptyIconPrograms } from '../../../assets/svg/Illustrations/emptyState.svg';
import { ReactComponent as EmptyIconApplications } from '../../../assets/svg/Illustrations/emptyStateApplication.svg';
import { ReactComponent as EmptyIconStatus } from '../../../assets/svg/Illustrations/emptyStateStatus.svg';
import { EmptyIllustration, EmptyStateWrapper } from './EmptyState.style';

interface Props {
    type?: 'programs' | 'suggested' | 'saved' | 'applications' | 'status';
}

export const EmptyState = ({ type = 'programs' }: Props) => {
    const { isMobile } = useBreakpoints();
    return (
        <>
            <EmptyStateWrapper isMobile={isMobile}>
                <EmptyIllustration isMobile={isMobile}>
                    {type === 'programs' ? (
                        <EmptyIconPrograms
                            style={{ width: 'inherit', height: 'inherit' }}
                        />
                    ) : type !== 'status' ? (
                        <EmptyIconApplications
                            style={{ width: 'inherit', height: 'inherit' }}
                        />
                    ) : (
                        <EmptyIconStatus
                            style={{ width: 'inherit', height: 'inherit' }}
                        />
                    )}
                </EmptyIllustration>
                <Box display="grid" gridRowGap={isMobile ? '8px' : undefined}>
                    <UniTypography
                        variant={isMobile ? 'body2' : 'header3'}
                        weight={isMobile ? 'medium' : 'semi-bold'}
                        color="secondary100"
                        style={
                            !isMobile
                                ? { display: 'flex', alignItems: 'center' }
                                : undefined
                        }
                    >
                        Ooops! It looks empty around here
                    </UniTypography>
                    <UniTypography
                        variant="body2"
                        weight="regular"
                        color="typographyGray100"
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        Try searching for something you like.
                    </UniTypography>
                </Box>
            </EmptyStateWrapper>
        </>
    );
};
