import styled from 'styled-components';
import { Colors, Shadows, theme } from '../../../theme';

export const StyledWrapper = styled.div<{
    grey?: boolean;
    opac: boolean;
    size: any;
    border: any;
    hoverable: boolean;
    shadow?: 'shadow1' | 'shadow2' | 'shadow3';
    onboard?: boolean;
    fit?: boolean;
}>`
    border-radius: ${({ size }) =>
        size === 'L' ? theme.spacing(1.5) : theme.spacing(1)}px;
    background-color: ${({ grey }) =>
        !!grey ? Colors.Gray1._70 : Colors.White._100};
    border: ${({ border, opac }) =>
        border
            ? `1px solid  ${
                  !opac ? Colors.Border.Gray_100 : Colors.Border.Gray_70
              }`
            : undefined};
    transition: all 0.2s;
    position: relative;
    height: inherit;
    width: inherit;
    ${({ shadow }) => !!shadow && `box-shadow: ${Shadows[shadow]};`}
    ${({ hoverable }) =>
        hoverable &&
        `&:hover { 
      box-shadow: ${Shadows.shadow2}; 
      cursor:pointer;
  }`}
  ${({ onboard }) => onboard && 'max-width:782px'}
  ${({ fit }) =>
        fit &&
        `
    width:100%;
    height:100%;
  `}
`;
