import { createTheme } from '@material-ui/core';
import themeOverrides from './themeOverrides';
import themeProps from './themeProps';
import { Colors, Shape } from './uiConfig';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 320,
            md: 600,
            lg: 1023,
            xl: 1920,
        },
    },
    shape: {
        borderRadius: Shape.BorderRadius,
    },
    palette: {
        primary: {
            contrastText: Colors.White._100,
            main: Colors.Primary._100,
        },
        secondary: {
            contrastText: Colors.White._100,
            main: Colors.Secondary._100,
        },
    },

    typography: {
        fontFamily: 'Poppins, sans-serif',
        fontWeightLight: 400,
        fontWeightRegular: 500,
        fontWeightBold: 600,
    },
});

theme.overrides = themeOverrides;
theme.props = themeProps;

export default theme;
