import React from 'react';
import { StyledOAuthButton, StyledOAuthWrapper } from './UniOAuth.style';

interface Props {
    google?: boolean;
    facebook?: boolean;
    login?: boolean;
    signup?: boolean;
    short?: boolean;
}

const UniOAuth: React.FC<Props> = ({
    google,
    facebook,
    login,
    signup,
    short,
}) => {
    const labelGoogle = short
        ? 'Google'
        : login
        ? 'Login with Google'
        : signup
        ? 'Signup with Google'
        : '';
    const labelFaebook = short
        ? 'Facebook'
        : login
        ? 'Login with Facebook'
        : signup
        ? 'Signup with Facebook'
        : '';

    const onGoogleLogin = () => window.open('/login/google');
    const onFacebookLogin = () => window.open('/login/facebook');

    return (
        <StyledOAuthWrapper>
            {google && (
                <StyledOAuthButton
                    onClick={onGoogleLogin}
                    google
                    variant="secondary"
                >
                    {labelGoogle}
                </StyledOAuthButton>
            )}
            {facebook && (
                <StyledOAuthButton
                    onClick={onFacebookLogin}
                    facebook
                    variant="secondary"
                >
                    {labelFaebook}
                </StyledOAuthButton>
            )}
        </StyledOAuthWrapper>
    );
};

export default UniOAuth;
