export const Colors = {
    Primary: {
        _100: '#D12E71',
        _70: '#D12E71B2',
        _50: '#D12E7180',
        _10: '#D12E711A',
        _5: '#D12E710D',
    },
    White: {
        _100: '#FFFFFF',
        _30: 'rgba(255, 255, 255, 0.3)',
        _10: 'rgba(255, 255, 255, 0.1);',
    },
    Gray1: {
        _100: '#F7F6F4',
        _70: 'rgba(247, 246, 244, 0.7)',
    },
    Gray2: {
        _100: '#EFEEED',
        _80: 'rgba(239, 238, 237, 0.8)',
        _40: 'rgba(239, 238, 237, 0.4)',
    },
    Typography: {
        Black_100: '#373737',
        Gray_100: '#848383',
        Gray_70: 'rgba(132, 131, 131, 0.7)',
        Gray_50: 'rgba(132, 131, 131, 0.5)',
        Gray_30: 'rgba(132, 131, 131, 0.3)',
        Gray_10: 'rgba(132, 131, 131, 0.1)',
    },
    Border: {
        Gray_100: '#D0D0D0',
        Gray_70: 'rgba(208, 208, 208, 0.7)',
    },
    State: {
        Alert_100: '#EE5039',
    },
    Secondary: {
        _100: '#49275C',
        _70: '#49275CB2',
        _50: '#49275C80',
        _10: '#49275C1A',
        _5: '#49275C0D',
    },
    Other: {
        Purple_100: '#9C56B8',
        Yellow_100: '#F8CB03',
        Green_100: '#219653',
        Hover_05: 'rgba(0, 0, 0, 0.05)',
        Google_100: '#EB5757',
        Facebook_100: '#2F80ED',
    },
} as const;

export const Shadows = {
    shadow1: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    shadow2: '0px 8px 32px -10px rgba(0, 0, 0, 0.3)',
    shadow3: '0px 16px 44px -12px rgba(0, 0, 0, 0.4)',
    shadow4: '0px -1px 0px 0px #00000026',
};
export const Typography = {
    small: 10,
    footnote: 12,
    subheader: 14,
    body2: 16,
    body1: 18,
    header3: 21,
    header2: 24,
    header1: 28,
    xl: 32,
    Weight: {
        Regular: 400,
        Medium: 500,
        SemiBold: 600,
    },
} as const;

export const Shape = {
    BorderRadius: 12,
    BorderRadius1: 30,
};

export const Sizes = {
    MaxWidthNavbar: 1440,
    MaxWidthWrapper: 1280,
    AuthMaxPageWidth: 1280,
    AuthNavbarHeight: 80,
    DrawerWidth: 240,
    DrawerWidthCollapsed: 80,
} as const;

const UiConfig = {
    Colors,
    Typography,
    Shape,
    Sizes,
    Shadows,
} as const;

export default UiConfig;
