import { Box } from '@material-ui/core';
import { ReactComponent as HeartEmptyIcon } from 'assets/svg/Icons/Heart.svg';
import { ReactComponent as HeartFullIcon } from 'assets/svg/Icons/HeartFull.svg';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { UniChip } from '../..';
import { TComponentColors } from '../../../theme';
import UniIconWrapper from '../uniIconWrapper/UniIconWrapper';
import { StyledBox, StyledFooterBox } from './UniCardFooter.style';

export interface UniCardFooterProps {
    isFavorite?: boolean;
    onFavorite?: (value: boolean) => void;
    PrimaryButton: React.ReactElement;
    SecondaryButton?: React.ReactElement;
    variants?: 'XS' | 'S' | 'M' | 'L';
    status?:
        | 'NEW'
        | 'VALIDATED'
        | 'SUBMITTED'
        | 'IN_REVIEW'
        | 'INCOMPLETE'
        | 'CONDITIONAL_ADMISSION'
        | 'PASSED'
        | 'ENROLLED'
        | 'FAILED';
    type?:
        | 'base'
        | 'baseUnlabeled'
        | 'baseStatus'
        | 'baseUnlabeledStatus'
        | 'labeled'
        | 'basic';
    style?: any;
    icon?: React.ReactElement;
    onIconClick?: Function;
    iconColor?: TComponentColors;
}

export const UniCardFooter: React.FC<UniCardFooterProps> = ({
    onFavorite,
    isFavorite = false,
    variants = 'L',
    type = 'base',
    PrimaryButton,
    status,
    SecondaryButton,
    icon,
    onIconClick,
    iconColor,
    ...rest
}) => {
    const [showLabel1] = useState(
        type === 'base' || type === 'labeled' || type === 'baseStatus'
    );
    const [showHeart] = useState(
        type === 'base' ||
            type === 'baseUnlabeled' ||
            type === 'baseStatus' ||
            type === 'baseUnlabeledStatus'
    );
    const [showStatus] = useState(
        type === 'baseStatus' || type === 'baseUnlabeledStatus'
    );
    const [fullHeart, setFullHeart] = useState(isFavorite);

    const iconClickHandler = () => {
        if (!!onIconClick && !!icon) onIconClick();
    };

    useEffect(() => setFullHeart(isFavorite), [isFavorite]);

    return (
        <StyledFooterBox size={type} variants={variants} {...rest}>
            <Box display="flex" style={{ alignItems: 'center' }}>
                {!!onFavorite && showHeart && (
                    <UniIconWrapper
                        onClick={() => {
                            onFavorite && onFavorite(!fullHeart);
                        }}
                        color={fullHeart ? 'primary100' : 'typographyGray100'}
                    >
                        <AnimatePresence>
                            {!fullHeart && (
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                >
                                    <HeartEmptyIcon />
                                </motion.div>
                            )}
                            {fullHeart && (
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                >
                                    <HeartFullIcon />
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </UniIconWrapper>
                )}
                {!!icon && (
                    <UniIconWrapper
                        color={iconColor}
                        onClick={iconClickHandler}
                    >
                        {icon}
                    </UniIconWrapper>
                )}
                {showStatus && <UniChip color="green" label={status} />}
            </Box>
            <Box display="flex" style={{ alignItems: 'center' }}>
                {showLabel1 && !!SecondaryButton && (
                    <StyledBox variants={variants}>{SecondaryButton}</StyledBox>
                )}
                {PrimaryButton}
            </Box>
        </StyledFooterBox>
    );
};
