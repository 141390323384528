import create, { State } from 'zustand';

interface AuthState extends State {
    code?: string;
    setCode: (value: string) => void;
}

const useAuth = create<AuthState>((set) => ({
    code: localStorage.getItem('code') || undefined,
    setCode: (code) => {
        localStorage.setItem('code', code);
        set((state) => ({ ...state, code }));
    },
}));

export default useAuth;
