import styled from 'styled-components'

export const EmptyStateWrapper = styled.div<{isMobile:boolean}>`
${({isMobile, theme}) => isMobile ? 
`
    display:grid;
    margin: ${theme.spacing(2)}px;
    justify-content:center;
    text-align:center;`
    : 
    `
    display:flex;
    margin: ${theme.spacing(4)}px;`
}`

export const EmptyIllustration = styled.div<{isMobile:boolean}>`
${({isMobile, theme})=> isMobile ? 
    `
    height: ${theme.spacing(6)}px; 
    width: ${theme.spacing(6)}px; 
    margin: 0 auto ${theme.spacing(3)}px auto;`
:
    `
    height: ${theme.spacing(12.5)}px; 
    width: ${theme.spacing(12.5)}px; 
    margin-right: ${theme.spacing(2)}px;
    `
}
`
