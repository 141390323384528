import ContactUs from 'components/_common/contactUs/ContactUs';
import React, { useState } from 'react';
import SearchPageDrawer from '../search-page/SearchPageDrawer';
import AboutUsContent from './AboutUsContent';
import AboutUsHeader from './AboutUsHeader';

interface Props {}

const AboutUs = (props: Props) => {
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

    return (
        <>
            <AboutUsHeader
                isLoggedIn={false}
                canTry={true}
                // searchTries={searchTries}
                setDrawerOpen={setDrawerOpen}
            />
            <AboutUsContent />
            <SearchPageDrawer
                canTry={true}
                drawerOpen={drawerOpen}
                setDrawerOpen={setDrawerOpen}
            />
            <ContactUs />
        </>
    );
};

export default AboutUs;
