import React from 'react';
import { TComponentColors } from '../../../theme';
import { Transform, Variant, Weight } from './types';
import { StyledTypography } from './UniTypography.style';

export type UniTypographyProps = {
    component?: React.ElementType;
    variant?: Variant;
    weight?: Weight;
    color?: TComponentColors;
    transform?: Transform;
    style?: React.CSSProperties;
};
const VariantMap = {
    xl: 'h1',
    header1: 'h2',
    header2: 'h3',
    header3: 'h4',
    body1: 'body1',
    body2: 'body2',
    subheader: 'subtitle1',
    footnote: 'caption',
    small: 'overline',
} as const;

const UniTypography: React.FC<UniTypographyProps> = ({
    variant = 'body2',
    color = 'typographyBlack100',
    weight = 'regular',

    children,
    transform = 'none',
    style,
    ...props
}) => {
    return (
        <StyledTypography
            transform={transform}
            component="p"
            {...props}
            unicolor={color}
            weight={weight}
            variant={VariantMap[variant]}
            style={style}
        >
            {children}
        </StyledTypography>
    );
};

export default UniTypography;
