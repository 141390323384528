// import { UniCardFooter } from '../../_common/uniCardFooter/UniCardFooter';
import { UniCardFooter } from 'components';
import styled from 'styled-components';
import { theme } from 'theme';
import { ReactComponent as ApplicationSuccessIllustration } from '../../../assets/svg/Illustrations/invitationSentIllustration.svg';
import { Colors, Shadows } from '../../../theme';

export const StyledCountriesWrapper = styled.div`
    padding-top: ${theme.spacing(2)}px;
    padding-bottom: ${theme.spacing(1)}px;
    margin-top: ${theme.spacing(-2)}px;
    margin-bottom: ${theme.spacing(2)}px;
    display: flex;
    overflow: auto;
    scrollbar-color: ${Colors.Primary._100};
    scrollbar-width: thin;

    /* width */
    &::-webkit-scrollbar {
        height: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: ${Colors.Secondary._100};
        border-radius: 50px;
    }
`;

export const StyledSuccessIllustration = styled(ApplicationSuccessIllustration)`
    width: 100%;
    max-width: 300px;
    max-height: 200px;
`;

export const StyledDrawerWrapper = styled.div<{ isOpen: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    transition: 0.2s;

    background-color: transparent;
    z-index: 999;
    overflow-y: auto;
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */
    &::-webkit-scrollbar {
        display: none;
    }

    ${({ isOpen }) =>
        isOpen ? 'pointer-events: initial;' : 'pointer-events: none;'}
    ${({ isOpen }) =>
        isOpen && `background-color: ${Colors.Typography.Gray_70};`}
`;

export const StyledFixedDrawer = styled.div<{
    isOpen: boolean;
    side?: 'left' | 'right';
}>`
    position: fixed;
    top: 0;
    ${({ side }) => (side === 'left' ? 'left: 0;' : 'right: 0;')}
    overflow: hidden;
    background-color: ${Colors.White._100};

    transition: 0.2s;

    width: 100%;
    height: 100%;
    max-width: 0;

    display: flex;
    flex-direction: column;
    box-shadow: ${Shadows.shadow2};

    ${({ isOpen }) => isOpen && 'max-width: 420px'};
    ${theme.breakpoints.down('sm')} {
        ${({ isOpen }) => isOpen && 'max-width: 100%'};
    }
`;

export const StyledModalDrawerActions = styled(({ ...props }) => (
    <UniCardFooter {...props} />
))`
    box-shadow: ${Shadows.shadow4};
`;

export const UniModalDrawerHeaderWrapper = styled.div`
    padding: ${({ theme }) => theme.spacing(3, 3, 2, 3)};
    background-color: ${Colors.Gray2._40};

    ${theme.breakpoints.down('sm')} {
        padding: ${({ theme }) => theme.spacing(3, 2, 2, 2)};
    }
`;

export const UniModalDrawerContent = styled.div`
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding: ${theme.spacing(4, 3)};
    ${theme.breakpoints.down('sm')} {
        padding: ${theme.spacing(3, 2)};
    }
`;

export const StyledUniWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: ${({ theme }) => theme.spacing(3, 3)};
    ${({ theme }) => `${theme.breakpoints.down('sm')}{
    grid-gap: ${theme.spacing(2, 2)};
  }`}
    width: 100%;
    & > * {
        flex: 1;
        margin-bottom: 16px;
    }
`;
