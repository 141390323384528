import React from 'react';
import { TComponentColors } from '../../../theme';
import { Icon, StyledBadge, StyledIconButton } from './UniIconWrapper.style';

export type Size = 'xsmall' | 'small' | 'main' | 'medium' | 'large';

export type UniIconWrapperProps = {
    size: Size;
    disabled: boolean;
    color: TComponentColors;
    alert: boolean;
    onClick: (event: React.MouseEvent<HTMLElement>) => void;
    withBackground?: boolean;
    hasBorderRadius?: boolean;
    marginClearFilter?: boolean;
    clearMargins?: boolean;
    onMouseEnter?: (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => void;
    onMouseLeave?: (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => void;
    opacity?: number;
    disableHover?: boolean;
    disableRipple?: boolean;
};

const UniIconWrapper: React.FC<Partial<UniIconWrapperProps>> = ({
    children,
    size = 'medium',
    color = 'typographyBlack100',
    alert = false,
    onClick,
    disabled = false,
    withBackground = false,
    hasBorderRadius = false,
    marginClearFilter = false,
    onMouseEnter,
    onMouseLeave,
    disableHover,
    disableRipple = false,
    opacity = 1,
    clearMargins,
}) => {
    const hasOnClickFunction = typeof onClick === 'function';
    return (
        <>
            {hasOnClickFunction ? (
                <StyledIconButton
                    marginClearFilter={marginClearFilter}
                    disableHover={disableHover}
                    disableRipple={disableRipple}
                    disabled={disabled}
                    clearMargins={clearMargins}
                    color="inherit"
                    onClick={(e: any) => {
                        e.stopPropagation();
                        onClick && onClick(e);
                    }}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                >
                    <StyledBadge
                        ccolor={disabled ? 'typographyGray70' : color}
                        withBackground={withBackground}
                        size={size}
                        variant={'dot'}
                        invisible={!alert}
                        style={{
                            borderRadius: `${hasBorderRadius ? '50%' : ''}`,
                        }}
                    >
                        <Icon
                            opacity={opacity}
                            color={disabled ? 'typographyGray70' : color}
                            size={size}
                        >
                            {children}
                        </Icon>
                    </StyledBadge>
                </StyledIconButton>
            ) : (
                <StyledBadge
                    ccolor={disabled ? 'typographyGray70' : color}
                    withBackground={withBackground}
                    size={size}
                    variant={'dot'}
                    invisible={!alert}
                >
                    <Icon opacity={opacity} color={color} size={size}>
                        {children}
                    </Icon>
                </StyledBadge>
            )}
        </>
    );
};

export default UniIconWrapper;
