import { useMediaQuery } from '@material-ui/core';
import { theme } from '../theme';
// use Effect after the first mount (defer)
const useBreakpoints = () => {
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return { isMobile, isTablet };
};

export default useBreakpoints;
