import { UniLogo, UniTypography } from 'components';
import styled from 'styled-components';
import { Colors, theme } from 'theme';

export const StyledContentWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: ${theme.spacing(7, 2)};
    padding-top: ${theme.spacing(7)}px;
    max-width: 1280px;
    margin: 0 auto;

    > * {
        width: 40%;
        min-width: 300px;
    }
`;

export const StyledLogo = styled(UniLogo)`
    height: 65px;
    width: 64px;

    svg {
        width: 100%;
        height: 100%;
    }
`;

export const StyledUniTypography = styled(UniTypography)`
    font-size: 60px;
    line-height: 1.1em;
    /* font-weight: 900; */
    font-weight: 700;
`;

export const StyledUnderline = styled.div`
    margin-top: 16px;
    height: 6px;
    width: 32px;
    border-radius: 50px;
    background-color: ${Colors.Other.Yellow_100};
`;
