import styled from 'styled-components';
import { ReactComponent as LogoName } from '../../../assets/svg/LogoNameDark.svg';
import { Colors } from '../../../theme';

export const StyledWrapper = styled.div`
    position: relative;
    width: 200px;
    height: 60px;
    margin: auto;
    overflow: visible;
`;

export const Circle = styled.div`
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 50%;
    background-color: ${Colors.Primary._100};
    left: 15%;
    transform-origin: 50%;
    z-index: 1;
    animation: circle 0.5s alternate infinite ease;

    @keyframes circle {
        0% {
            top: 60px;
            height: 5px;
            border-radius: 50px 50px 25px 25px;
            transform: scaleX(1.7);
        }
        40% {
            height: 20px;
            border-radius: 50%;
            transform: scaleX(1);
        }
        100% {
            top: 0;
        }
    }
    &:nth-child(2) {
        left: 45%;
        animation-delay: 0.2s;
    }
    &:nth-child(3) {
        left: auto;
        right: 15%;
        animation-delay: 0.3s;
    }
`;

export const Shadow = styled.div`
    width: 20px;
    height: 4px;
    border-radius: 50%;
    background-color: ${Colors.Secondary._100};
    position: absolute;
    top: 62px;
    transform-origin: 50%;
    z-index: -1;
    left: 15%;
    filter: blur(1px);
    animation: shadow 0.5s alternate infinite ease;

    @keyframes shadow {
        0% {
            transform: scaleX(1.5);
        }
        40% {
            transform: scaleX(1);
            opacity: 0.7;
        }
        100% {
            transform: scaleX(0.2);
            opacity: 0.4;
        }
    }
    &:nth-child(4) {
        left: 45%;
        animation-delay: 0.2s;
    }
    &:nth-child(5) {
        left: auto;
        right: 15%;
        animation-delay: 0.3s;
    }
`;

export const StyledLogoName = styled(LogoName)`
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
`;
