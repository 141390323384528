import styled from 'styled-components';
import { UniButton } from '../..';
import { Colors } from '../../../theme';

export const StyledOAuthWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${({ theme }) => theme.spacing(1)}px;
    margin-bottom: ${({ theme }) => theme.spacing(3)}px;
    width: 100%;
    ${({ theme }) => `${theme.breakpoints.down('sm')}{
    grid-template-columns: 1fr;
    margin-bottom: ${theme.spacing(1)}px;
  }`}
`;

export const StyledOAuthButton = styled(({ google, facebook, ...props }) => (
    <UniButton {...props} />
))<{
    google?: boolean;
    facebook?: boolean;
}>`
    ${({ google, facebook }) =>
        facebook
            ? `border-color: ${Colors.Other.Facebook_100}; p {color: ${Colors.Other.Facebook_100}}`
            : google &&
              `border-color: ${Colors.Other.Google_100}; p {color: ${Colors.Other.Google_100}}`}

    ${({ theme }) => `${theme.breakpoints.down('sm')}{
    flex: 1;
  }`}
`;
