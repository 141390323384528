import React from 'react';
import { StyledHeader, StyledInnerHeader } from './UniHeader.style';

export const UniHeader: React.FC = ({ children }) => {
  return (
    <StyledHeader>
      <StyledInnerHeader>{children}</StyledInnerHeader>
    </StyledHeader>
  );
};
