import { Box } from '@material-ui/core';
import styled from 'styled-components';
import { Colors } from '../../../theme';

export const StyledFooterBox = styled(Box)<{
    size: any;
    variants: 'XS' | 'S' | 'M' | 'L';
}>`
    position: relative;
    display: flex;
    justify-content: ${({ size }) =>
        size === 'base' ||
        size === 'baseUnlabeled' ||
        size === 'baseUnlabeledStatus' ||
        size === 'baseStatus'
            ? 'space-between'
            : 'flex-end'};
    align-items: center;
    padding: ${({ theme, variants }) =>
        variants === 'L' ? theme.spacing(2, 3) : theme.spacing(2, 2)};
    background-color: ${Colors.White._100};
    height: ${({ variants, theme }) =>
        variants === 'XS'
            ? theme.spacing(9)
            : variants === 'M'
            ? theme.spacing(10)
            : theme.spacing(13)}px;
    width: 100%;
    &::before {
        content: '';
        border-radius: inherit;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: ${Colors.Gray2._40};
    }
`;

export const StyledBox = styled(Box)<{ variants: any }>`
    margin-right: ${({ variants, theme }) =>
        variants === 'S' ? theme.spacing(1.5) : theme.spacing(2.5)}px;
`;
