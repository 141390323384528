import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import { Sizes } from '../../../theme';

const UniAuthPageStyled = styled(({ children, ...rest }) => {
    return (
        <>
            <Grid container spacing={2} {...rest}>
                {children}
            </Grid>
        </>
    );
})`
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    margin-bottom: ${({ theme }) => theme.spacing(4)}px;
    align-content: flex-start;
    min-height: ${({ theme }) =>
        `calc(100vh - ${Sizes.AuthNavbarHeight}px - ${theme.spacing(5)}px)`};
    ${({ theme }) => theme.breakpoints.up('lg')} {
        max-width: ${Sizes.MaxWidthWrapper}px;
    }
`;

export default UniAuthPageStyled;
