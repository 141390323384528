import queryString from 'query-string';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import countriesIso from '../common/countryIso.json';

const useProgramsMap = (categories: any[]) => {
    const [programsMap, setProgramsMap] = useState<any[]>([]);
    const { search } = useLocation();
    const { country: queryCountries } = queryString.parse(search) as {
        country: string[];
    };

    useMemo(() => {
        const auxMap = categories.reduce((prev, category) => {
            const countriesMap = category.programs?.reduce(
                (prevPrograms: any[], program: any) => {
                    const prevProgIndex = prevPrograms.findIndex(
                        (prevProgram: any) =>
                            prevProgram.code === program.address_country
                    );

                    if (prevProgIndex === -1) {
                        const isoCountry = countriesIso.find(
                            (country) =>
                                country.alpha2 === program.address_country
                        );

                        if (isoCountry) {
                            if (
                                queryCountries &&
                                ![queryCountries]
                                    .flat(1)
                                    .includes(isoCountry.alpha2)
                            ) {
                                return prevPrograms;
                            }
                            prevPrograms.push({
                                name: isoCountry.name,
                                code: isoCountry.alpha2,
                                flag: isoCountry.alpha3,
                                count: 1,
                                programs: [{ ...program, key: category.key }],
                            });
                        }
                    } else {
                        prevPrograms[prevProgIndex].count += 1;
                        prevPrograms[prevProgIndex].programs.push({
                            ...program,
                            key: category.key,
                        });
                    }

                    return prevPrograms;
                },
                []
            );

            const resultsCount = countriesMap.reduce(
                (total: number, item: any) =>
                    total +
                    item.programs.reduce(
                        (totalProg: number, program: any) => totalProg + 1,
                        0
                    ),
                0
            );

            if (!resultsCount) {
                return prev;
            }

            return [
                ...prev,
                {
                    key: category.key,
                    countries: countriesMap,
                    count: resultsCount,
                },
            ];
        }, []);

        setProgramsMap(auxMap);
    }, [categories]);

    return programsMap;
};

export default useProgramsMap;
