import { Box } from '@material-ui/core';
import { ReactComponent as LinkIcon } from 'assets/svg/Icons/Link.svg';
import { ReactComponent as MailIcon } from 'assets/svg/Icons/Mail.svg';
import { ReactComponent as MessagesIcon } from 'assets/svg/Icons/Messages.svg';
import { ReactComponent as PhoneIcon } from 'assets/svg/Icons/PhoneIcon.svg';
import { UniIconWrapper, UniTypography } from 'components';
import React, { useState } from 'react';
import { UniMetricsBase } from '../uniMetricsBase/UniMetricsBase';
import {
    StyledDrawerWrapper,
    StyledFixedDrawer,
    UniModalDrawerContent,
} from '../uniModalDrawer/UniModalDrawer.style';
import UniModalDrawerHeader from '../uniModalDrawer/UniModalDrawerHeader';
import { StyledContactUsWrapper } from './ContactUs.style';

interface Props {}

const ContactUs = (props: Props) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <>
            <StyledDrawerWrapper isOpen={isOpen}>
                <StyledFixedDrawer
                    side={'right'}
                    isOpen={isOpen}
                    onClick={(e) => e.stopPropagation()}
                >
                    <UniModalDrawerHeader
                        title={'Contact us'}
                        onClose={() => setIsOpen(false)}
                    />
                    <UniModalDrawerContent>
                        <UniMetricsBase
                            Icon={
                                <UniIconWrapper color="primary100">
                                    <MailIcon />
                                </UniIconWrapper>
                            }
                            label="Email"
                            link="mailto:office@uniapply.org"
                            value="office@uniapply.org"
                            variant="iconed"
                        />
                        <Box mt={2} />
                        <UniMetricsBase
                            Icon={
                                <UniIconWrapper color="primary100">
                                    <PhoneIcon />
                                </UniIconWrapper>
                            }
                            label="Phone"
                            link="tel:+40727760308"
                            value="+40 727 760 308"
                            variant="iconed"
                        />
                        <Box mt={2} />
                        <UniMetricsBase
                            Icon={
                                <UniIconWrapper color="primary100">
                                    <LinkIcon />
                                </UniIconWrapper>
                            }
                            label="Website"
                            link="https://uniapply.org"
                            value="https://uniapply.org"
                            variant="iconed"
                        />
                    </UniModalDrawerContent>
                </StyledFixedDrawer>
            </StyledDrawerWrapper>
            <StyledContactUsWrapper onClick={() => setIsOpen(true)}>
                <UniIconWrapper size="main" color="white100">
                    <MessagesIcon />
                </UniIconWrapper>
                <UniTypography
                    variant="body2"
                    weight="regular"
                    color="white100"
                >
                    Contact us
                </UniTypography>
            </StyledContactUsWrapper>
        </>
    );
};

export default ContactUs;
