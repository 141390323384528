import { ComponentsProps } from "@material-ui/core/styles/props";

// Change default props for all components
export default {
  MuiButton: {
    disableElevation: true
  },
  MuiTooltip: {
    arrow: true
  }
} as ComponentsProps;
