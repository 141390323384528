import ReactPixel from 'react-facebook-pixel';
import create, { State } from 'zustand';

interface PixelState extends State {
    init: () => void;
    track: (event: string, data: any) => void;
}

const options = {
    autoConfig: true,
    debug: true,
};

const usePixel = create<PixelState>((set) => ({
    init: () => {
        ReactPixel.init('1049955425580010', undefined, options);
    },
    track: (event, data) => {
        ReactPixel.trackCustom(event, {
            ...data,
            test_event_code: 'TEST51506',
        });
    },
}));

export default usePixel;
