import { CssBaseline } from '@material-ui/core';
import { StylesProvider, ThemeProvider } from '@material-ui/styles';
import AboutUs from 'components/templates/about-us/AboutUs';
import PixelProvider from 'components/_common/pixelProvider/PixelProvider';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { Layout, SearchPage, VerifyCode as VerifyCodePage } from './components';
import { theme } from './theme';

function App() {
    return (
        <StylesProvider injectFirst>
            <StyledThemeProvider theme={theme}>
                <ThemeProvider theme={theme}>
                    <PixelProvider>
                        <CssBaseline />
                        <BrowserRouter>
                            <Layout>
                                <Switch>
                                    <Route
                                        exact
                                        path="/"
                                        component={SearchPage}
                                    />
                                    <Route
                                        exact
                                        path="/about"
                                        component={AboutUs}
                                    />
                                    <Route
                                        path="/verify"
                                        component={VerifyCodePage}
                                    />
                                    <Route
                                        path="/login/confirm"
                                        render={() => (
                                            <Redirect to="/verify?code=SOCIAL" />
                                        )}
                                    />
                                </Switch>
                            </Layout>
                        </BrowserRouter>
                    </PixelProvider>
                </ThemeProvider>
            </StyledThemeProvider>
        </StylesProvider>
    );
}

export default App;
