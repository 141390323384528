import { StyledWrapper } from './UniCardWrapper.style';

export interface UniCardWrapperProps {
  size: 'S' | 'L';
  border?: boolean;
  shadow?: 'shadow1' | 'shadow2' | 'shadow3' | 'none';
  opacBorder?: boolean;
  hoverable?: boolean;
  grey?: boolean;
  onClick?: Function;
  fit?: boolean;
  padding?: [number, number];
}

const UniCardWrapper: React.FC<UniCardWrapperProps> = ({
  size,
  fit,
  opacBorder = false,
  border = false,
  children,
  onClick,
  shadow,
  hoverable = false,
  grey,
  ...props
}) => {
  return (
    <StyledWrapper
      onClick={() => onClick && onClick()}
      fit={fit}
      opac={opacBorder}
      size={size}
      border={border}
      shadow={shadow !== 'none' ? shadow : undefined}
      grey={grey}
      hoverable={hoverable}
      {...props}
    >
      {children}
    </StyledWrapper>
  );
};

export default UniCardWrapper;
