import { UniTypography } from '../..';
import { ReactComponent as CloseIcon } from '../../../assets/svg/Icons/Close.svg';
import { StyledCloseIcon, StyledTagItem } from './uniChip.style';

interface ChipProps {
    label: any;
    onDelete?: Function;
    color?: 'grey' | 'green';
}

const UniChip: React.FC<ChipProps> = ({ label, onDelete, color = 'grey' }) => {
    return (
        <StyledTagItem color={color}>
            <UniTypography
                variant="subheader"
                weight="regular"
                color={color === 'green' ? 'secondary100' : 'typographyGray70'}
            >
                <span style={{ whiteSpace: 'nowrap' }}>{label}</span>
            </UniTypography>

            {!!onDelete && (
                <StyledCloseIcon
                    color={color}
                    onClick={(e) => {
                        e.stopPropagation();
                        onDelete();
                    }}
                >
                    <CloseIcon />
                </StyledCloseIcon>
            )}
        </StyledTagItem>
    );
};

export default UniChip;
